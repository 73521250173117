import Slider from "react-slick/lib/slider";
import partnerImage1 from "../../common/images/partnerImage1.png";
import partnerImage2 from "../../common/images/partnerImage2.png";
import partnerImage3 from "../../common/images/partnerImage3.png";
import partnerImage4 from "../../common/images/partnerImage4.png";
import partnerImage5 from "../../common/images/partnerImage5.png";
import partnerImage6 from "../../common/images/partnerImage6.png";
import partnerImage7 from "../../common/images/partnerImage7.png";
import partnerImage8 from "../../common/images/partnerImage8.png";
import partnerImage9 from "../../common/images/partnerImage9.png";
import partnerImage10 from "../../common/images/partnerImage10.png";
import partnerImage11 from "../../common/images/partnerImage11.png";
import { useTranslation } from "react-i18next";

import "./index.scss";

const Partner = () => {
  const { t } = useTranslation();

  const data = [
    {
      image: partnerImage1,
    },
    {
      image: partnerImage2,
    },
    {
      image: partnerImage3,
    },
    {
      image: partnerImage4,
    },
    {
      image: partnerImage5,
    },
    {
      image: partnerImage6,
    },
    {
      image: partnerImage7,
    },
    {
      image: partnerImage8,
    },
    {
      image: partnerImage9,
    },
    {
      image: partnerImage10,
    },
    {
      image: partnerImage11,
    },
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1275,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 385,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className="partner" id="doi-tac">
      <section className="container">
        <h1>{t('partner')}</h1>
        <div className="line-hr">
          <hr />
        </div>
        <div className="partner-list">
          <Slider {...settings}>
            {data.length &&
              data.map((item, index) => (
                <div className="box" key={index}>
                  <div className="partner-item">
                    <img src={item.image} alt="" />
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default Partner;
