import { useTranslation } from "react-i18next";
import reasonImage1 from "../../common/images/reasonImage1.svg";
import reasonImage2 from "../../common/images/reasonImage2.svg";
import reasonImage3 from "../../common/images/reasonImage3.svg";
import reasonImage4 from "../../common/images/reasonImage4.svg";
import reasonImage5 from "../../common/images/reasonImage5.svg";
import reasonImage6 from "../../common/images/reasonImage6.png";

import "./index.scss";

const Reason = () => {
  const { t } = useTranslation();

  const data = [
    {
      name: t('reason.content.1.title'),
      des: t('reason.content.1.des'),
      image: reasonImage1,
    },
    {
      name: t('reason.content.2.title'),
      des: t('reason.content.2.des'),
      image: reasonImage2,
    },
    {
      name: t('reason.content.3.title'),
      des: t('reason.content.3.des'),
      image: reasonImage3,
    },
    {
      name: t('reason.content.4.title'),
      des: t('reason.content.4.des'),
      image: reasonImage4,
    },
    {
      name: t('reason.content.5.title'),
      des: t('reason.content.5.des'),
      image: reasonImage5,
    },
    {
      name: t('reason.content.6.title'),
      des: t('reason.content.6.des'),
      image: reasonImage6,
    },
  ];
  return (
    <div className="reason" id="reason">
      <section className="container">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1>{t('reason.title')}</h1>
        </div>
        <div className="line-hr">
          <hr />
        </div>
        <div className="reason-list">
          {data.length &&
            data.map((item, index) => (
              <div className="reason-list__item" key={index}>
                <div className="reason-list__item--image">
                  <img src={item.image} alt="" />
                </div>
                <div>
                  <h3 className="reason-list__item--title">{item.name}</h3>
                  <p className="reason-list__item--des">{item.des}</p>
                </div>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
};

export default Reason;
