import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiOutlineGlobal } from "react-icons/ai";
import "./index.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <section className="container">
        <h2 className="title">{t("info.title")}</h2>
        <hr />
        <div className="footer-content">
          <div className="footer-content__address">
            <div className="box">
              <span className="footer-content__address--title">
                {t("info.address.title1")}
              </span>
              <span className="footer-content__address--des">
                {t("info.address.1")}
              </span>
            </div>
            <div className="box">
              <span className="footer-content__address--title">
                {t("info.address.title2")}
              </span>
              <span className="footer-content__address--des">
                {t("info.address.2")}
              </span>
            </div>
            <div className="box">
              <span className="footer-content__address--title">
                {t("info.address.title3")}
              </span>
              <span className="footer-content__address--des">
                {t("info.address.3")}
              </span>
            </div>
            <div className="box">
              <span className="footer-content__address--title">
                {t("info.address.title4")}
              </span>
              <span className="footer-content__address--des">
                {t("info.address.4")}
              </span>
            </div>
          </div>
          <div className="footer-content__contact">
            <div>
              <div className="box">
                <span className="footer-content__address--title">
                  <FaPhoneAlt /> <span>Phone: </span>
                </span>
                <span className="footer-content__address--des">
                  +84 935 690 369
                </span>
              </div>
              <div className="box">
                <span className="footer-content__address--title">
                  <MdEmail /> <span>Email: </span>
                </span>
                <span className="footer-content__address--des">
                  business@bizverse.world
                </span>
              </div>
              <div className="box">
                <span className="footer-content__address--title">
                  <AiOutlineGlobal /> <span>Website: </span>
                </span>
                <a
                  className="footer-content__address--des"
                  href="https://bizverse.world/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://bizverse.io/
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
