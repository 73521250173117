import { useState } from "react";
import { Formik } from "formik";
import { FaTelegramPlane } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { useToasts } from "react-toast-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import "./index.scss";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();

  const onChange = () => {
    setDisabled(false);
  };

  const { addToast } = useToasts();
  return (
    <div className="contact" id="lien-he">
      <section className="container">
        <h1>{t('contact.title')}</h1>
        <div className="line-hr">
          <hr />
        </div>
        <Formik
          initialValues={{ email: "", full_name: "", phone: "", content: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Bắt buộc!";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Email chưa đúng!";
            }

            if (!values.full_name) {
              errors.full_name = "Bắt buộc!";
            }

            if (!values.phone) {
              errors.phone = "Bắt buộc!";
            } else if (
              !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(
                values.phone
              )
            ) {
              errors.phone = "Số điện thoại chưa đúng!";
            }

            return errors;
          }}
          onSubmit={(values) => {
            emailjs
              .send(
                "service_cpu9vox",
                "template_0yk72xh",
                values,
                "w4OBX2ADaLQIOO3Wv"
              )
              .then(
                function (response) {
                  addToast("Cảm ơn bạn đã gửi thông tin cho chúng tôi", {
                    appearance: "success",
                  });
                },
                function (error) {
                  addToast(error, { appearance: "error" });
                }
              );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="field-phone-name">
                <div>
                  <div className="input-bg">
                    <input
                      id="_reg_name"
                      autoComplete="off"
                      name="full_name"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.full_name}
                      placeholder={t('contact.name')}
                    />
                  </div>
                  <p className="input-err">
                    {errors.full_name && touched.full_name && errors.full_name}
                  </p>
                </div>
                <div>
                  <div className="input-bg">
                    <input
                      name="phone"
                      autoComplete="no"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      type="text"
                      placeholder={t('contact.phone')}
                    />
                  </div>
                  <p className="input-err">
                    {errors.phone && touched.phone && errors.phone}
                  </p>
                </div>
              </div>
              <div className="field-email">
                <div className="input-email">
                  <div className="input-bg">
                    <input
                      type="email"
                      name="email"
                      id="_reg_email"
                      autoComplete="_reg_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder={t('contact.email')}
                    />
                  </div>
                  <p className="input-err">
                    {errors.email && touched.email && errors.email}
                  </p>
                </div>
              </div>
              <div className="field-content">
                <div className="input-bg">
                  <textarea
                    name="content"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.content}
                    type="text"
                    placeholder={t('contact.content')}
                  />
                </div>
              </div>
              <div className="field-captcha">
                <div className="captcha">
                  <ReCAPTCHA
                    sitekey="6Lc878AfAAAAAF4ltx4G0zLQlgmEzQIvmJIDa6Sr"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="contact-form__btn">
                <button type="submit" className="btn-bg" disabled={disabled}>
                  <div className="btn-action">
                    <span className="icon">
                      <FaTelegramPlane size={20} />
                      <span className="submit">{t('contact.submit')}</span>
                    </span>
                  </div>
                </button>
              </div>
            </form>
          )}
        </Formik>
      </section>
    </div>
  );
};

export default Contact;
