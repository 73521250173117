import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import projectImage1 from "../../common/images/projectImage1.png";
import projectImage2 from "../../common/images/projectImage2.png";
import projectImage3 from "../../common/images/projectImage3.png";
import projectImage4 from "../../common/images/projectImage4.png";

import "./index.scss";

const Projects = () => {
  const { t } = useTranslation();

  const data = [
    {
      image: projectImage1,
      title: "Topal Event",
      des: t('project.content.1.des'),
    },
    // {
    //   image: projectImage2,
    //   title: "BHS Event",
    //   des: t('project.content.2.des'),
    // },
    // {
    //   image: projectImage3,
    //   title: "Herbalife Event",
    //   des: t('project.content.3.des'),
    // },
    {
      image: projectImage4,
      title: "NamHuong Media",
      des: t('project.content.4.des'),
    }
  ];
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1275,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 810,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className="project" id="du-an">
      <section className="container">
        <h1>{t('project.title')}</h1>
        <div className="line-hr">
          <hr />
        </div>
        <div className="project-list">
          <Slider {...settings}>
            {data.length &&
              data.map((item, index) => (
                <div key={index} className="box">
                  <div className="project-item">
                    <div>
                      <div className="project-item__image">
                        <img src={item.image} alt="projectImage1" />
                      </div>
                      <div className="project-item__title">
                        <h3>{item.title} </h3>
                      </div>
                      <div className="project-item__des">
                        <p>{item.des}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default Projects;
