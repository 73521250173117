import { useTranslation } from "react-i18next";
import solutionBg from "../../common/images/solutionBg.png";
import "./index.scss";

const Solution = () => {
  const { t } = useTranslation();

  return (
    <div className="solution" id="gioi-thieu">
      <section className="container">
        <div className="solution__content">
          <h1 className="solution__content--title">{t("solution.title")}</h1>
          <div className="hr-center">
            <hr />
          </div>
          <p className="solution__content--des">{t("solution.content")}</p>
          <div className="solution__content--btn">
            <a href="#du-an" className="btn-bg" style={{ marginRight: "35px" }}>
              <div className="btn-action">DEMO</div>
            </a>
            <a href="#lien-he" className="btn-bg">
              <div className="btn-action">{t("solution.button.quote")}</div>
            </a>
          </div>
        </div>
        <div className="solution__bg">
          <div className="solution__bg--layer">
            <div className="bg-layer"></div>
            <div className="solution__bg--image">
              <img src={solutionBg} alt="solutionBg" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Solution;
