import React, { useEffect, useRef, useState } from "react";
import Logo from "../../common/images/logo.png";
import { FiMoreHorizontal } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import viLgn from "../../common/images/viLgn.png";
import enLgn from "../../common/images/enLgn.png";
import { MdArrowDropDown } from "react-icons/md";

import "./header.scss";

const Header = (props) => {
  const { background } = props;
  const searchRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [showLgn, setShowLgn] = useState(false);
  const [language, setLanguage] = useState(true);

  const handleChangeLgn = () => {
    setLanguage(!language);
  };

  useEffect(() => {
    if (language) {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("vi");
    }
  }, [i18n, language]);

  return (
    <div className={`header ${background}`}>
      <section className="container" ref={searchRef}>
        <a href="#" className="logo">
          <img src={Logo} alt="Logo" />
        </a>
        <div className="menu">
          <div className="menu__content">
            <ul className="nav">
              <li>
                <a href="#">{t("header.home")}</a>
              </li>
              <li>
                <a href="#gioi-thieu">{t("header.about")}</a>
              </li>
              <li>
                <a href="#tinh-nang">{t("header.feature")}</a>
              </li>
              <li>
                <a href="#du-an">{t("header.project")}</a>
              </li>
              <li>
                <a href="#doi-tac">{t("header.partner")}</a>
              </li>
              <li>
                <a href="#lien-he">{t("header.contact")}</a>
              </li>
              <li className="nav__language">
                <div className="main-lgn" onClick={() => setShowLgn(!showLgn)}>
                  <div className="nav__language--image">
                    <img src={language ? enLgn : viLgn} alt="" />
                  </div>
                  <span>
                    {language ? <label>ENG</label> : <label>VN</label>}
                  </span>{" "}
                  <span className="icon">
                    <MdArrowDropDown />
                  </span>
                </div>
                <div className="sub-bg" onClick={() => handleChangeLgn()}>
                  <div className="sub-lgn">
                    <div className="nav__language--image">
                      <img src={!language ? enLgn : viLgn} alt="" />
                    </div>
                    <span>
                      {language ? <label>VN</label> : <label>ENG</label>}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="header__action isShow">
          <div className="nav">
            <div className="nav__language">
              <div className="main-lgn" onClick={() => setShowLgn(!showLgn)}>
                <div className="nav__language--image">
                  <img src={language ? enLgn : viLgn} alt="" />
                </div>
                <span>{language ? <label>ENG</label> : <label>VN</label>}</span>{" "}
                <span className="icon">
                  <MdArrowDropDown />
                </span>
              </div>
              <div className="sub-bg" onClick={() => handleChangeLgn()}>
                <div className="sub-lgn">
                  <div className="nav__language--image">
                    <img src={!language ? enLgn : viLgn} alt="" />
                  </div>
                  <span>
                    {language ? <label>VN</label> : <label>ENG</label>}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="header__action--more">
            <div className="header__action--icon">
              <FiMoreHorizontal />
            </div>
            <div className="more-menu">
              <div style={{ position: "relative" }}>
                <ul className="nav">
                  <li>
                    <a href="#">{t("header.home")}</a>
                  </li>
                  <li>
                    <a href="#gioi-thieu">{t("header.about")}</a>
                  </li>
                  <li>
                    <a href="#tinh-nang">{t("header.feature")}</a>
                  </li>
                  <li>
                    <a href="#du-an">{t("header.project")}</a>
                  </li>
                  <li>
                    <a href="#doi-tac">{t("header.partner")}</a>
                  </li>
                  <li>
                    <a href="#lien-he">{t("header.contact")}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
