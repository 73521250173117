import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Contact from "./Contact";
import Banner from "./Banner";
import Solution from "./Solution";
import Header from "../layouts/Header";
import Reason from "./Reason";
import SystemFeature from "./SystemFeature";
import Footer from "../layouts/Footer";
import zalo from "../common/images/zalo.png";
import { FaYoutube, FaFacebookF } from "react-icons/fa";
import Projects from "./Projects";
import Partner from "./Partner";
import { BsArrowUpCircle } from "react-icons/bs";
import bg1 from "../common/images/bg1.png";
import bg2 from "../common/images/bg2.png";
import bg3 from "../common/images/bg3.png";
import bg4 from "../common/images/bg4.png";
import bg5 from "../common/images/bg5.png";
import { ToastProvider } from "react-toast-notifications";

import "./index.scss";

const LandingPage = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);
  const handleScrollTop = () => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    navigate("/#");
    handleScrollTop();
  }, []);

  return (
    <ToastProvider>
      <Header background={visible ? "background-header" : ""} />
      <Banner />
      <Solution />
      <div className="section-container">
        <Reason />
        <SystemFeature />
        <Projects />
        <Partner />
        <Contact />
        <Footer />
      </div>
      <a
        className="scrollToTop"
        style={{ display: visible ? "inline" : "none" }}
      >
        <BsArrowUpCircle size={30} onClick={handleScrollTop} />
      </a>
      <hr style={{ width: "100%" }} />
      <div className="section-footer">
        <section>
          <h5>© 2022 BIZVERSE - The Metaverse for Business</h5>
          <div style={{ display: "flex" }}>
            <span className="fa-icon">
              <a
                className="icon-position"
                href="https://www.facebook.com/BizShowVirtualEventStudio/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF size={16} />
              </a>
            </span>
            <span className="fa-icon">
              <a
                className="icon-position"
                href="https://zalo.me/0935690369"
                rel="noreferrer"
                target="_blank"
              >
                <img src={zalo} alt="zalo" />
              </a>
            </span>
            <span className="fa-icon">
              <a
                className="icon-position"
                href="https://www.youtube.com/channel/UC3-kaPylMs7jLp86eqjiN4w"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube size={16} />
              </a>
            </span>
          </div>
        </section>
      </div>
      <div className="bg-image">
        <div className="bg-image-1">
          <img src={bg1} alt="bg1" />
        </div>
        <div className="bg-image-2">
          <img src={bg2} alt="bg2" />
        </div>
        <div className="bg-image-3">
          <img src={bg3} alt="bg3" />
        </div>
        <div className="bg-image-4">
          <img src={bg4} alt="bg4" />
        </div>
        <div className="bg-image-5">
          <img src={bg5} alt="bg5" />
        </div>
      </div>
    </ToastProvider>
  );
};

export default LandingPage;
