import { Suspense } from "react";
import LandingPage from "./pages";
import { BrowserRouter as Router } from "react-router-dom";
import "../src/styles/main.scss";

function App() {
  return (
    <Router className="App">
      <Suspense fallback={<div />}>
        <LandingPage />
      </Suspense>
    </Router>
  );
}

export default App;
