import React from "react";
import Modal from "react-modal";
import playStart from "../../common/images/playStart.png";
import videoIntro from "../../common/videos/videoIntro.mp4";
import "./index.scss";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "1px",
  },
};
const Banner = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="banner">
      <div className="video-container">
        <video autoPlay muted loop playsInline>
          <source src={videoIntro} type="video/mp4" />
        </video>
      </div>
      <div className="video-layer"></div>
      <div className="container">
        {!modalIsOpen && (
          <div className="banner__play" onClick={openModal}>
            <div className="img-play">
              <img src={playStart} alt="playStart" />
            </div>
          </div>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
        >
          <iframe
            src="https://www.youtube.com/embed/wqlSHoE0pAc?controls=1&autoplay=1&loop=1&mute=1&playlist=wqlSHoE0pAc"
            title="YouTube video player"
            allowFullScreen="allowFullScreen"
            autoPlay
            muted
            loop
            playsInline
          />
        </Modal>
      </div>
    </section>
  );
};

export default Banner;
