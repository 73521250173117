import { useTranslation } from "react-i18next";
import systemImage1 from "../../common/images/systemImage1.jpg";
import systemImage2 from "../../common/images/systemImage2.jpg";
import systemImage3 from "../../common/images/systemImage3.jpg";
import systemImage4 from "../../common/images/systemImage4.jpg";
import systemImage5 from "../../common/images/systemImage5.jpg";
import systemImage6 from "../../common/images/systemImage6.png";

import "./index.scss";

const SystemFeature = () => {
  const { t } = useTranslation();

  const data = [
    {
      name: t('feature.content.1.title'),
      des: t('feature.content.1.des'),
      image: systemImage1,
    },
    {
      name: t('feature.content.2.title'),
      des: t('feature.content.2.des'),
      image: systemImage2,
    },
    {
      name: t('feature.content.3.title'),
      des: t('feature.content.3.des'),
      image: systemImage3,
    },
    {
      name: t('feature.content.4.title'),
      des: t('feature.content.4.des'),
      image: systemImage4,
    },
    {
      name: t('feature.content.5.title'),
      des: t('feature.content.5.des'),
      image: systemImage5,
    },
    {
      name: t('feature.content.6.title'),
      des: t('feature.content.6.des'),
      image: systemImage6,
    },
  ];
  return (
    <div className="system-feature" id="tinh-nang">
      <section className="container">
        <h1>{t('feature.title')}</h1>
        <div className="line-hr">
          <hr />
        </div>
        <div className="system-feature-list">
          {data.length &&
            data.map((item, index) => (
              <div key={index} className={`system-feature-list__item ${[1, 3, 5].includes(index) ? "order-box" : ""
                }`}>
                <div
                  className={`system-feature-list__item--image ${[1, 3, 5].includes(index) ? "order-1" : ""
                    }`}
                >
                  <img src={item.image} alt="" />
                </div>
                <div className="system-feature-list__item--content">
                  <h3>{item.name}</h3>
                  <p>{item.des}</p>
                </div>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
};

export default SystemFeature;
